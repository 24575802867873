import React from 'react'
import "./about.css"
import Navbar from '../Navbar/Navbar'
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import foto from '../../Images/Foto.jpeg'

function About() {
  return (
    <section id="about" className="bg-white min-h-[600px] flex xl:flex-row sm:flex-col items-center xl:px-24 sm:px-6">
      <div className='sm:mt-6'>
        <div className='h-[400px] w-[300px] bg-[#f8f7ff] rounded-t-xl flex  items-center flex-col'>
          <img src={foto} alt="" className='rounded-full  xl:w-[150px] xl:h-[150px] sm:w-[150px] sm:h-[150px] mt-6 shadow-2xl' />
          <h5 className='mt-4 text-lg font-semibold'>Jorge Luis</h5>
          <h5 className='text-lg font-semibold border-b-2 border-blue-500 py-4 mt-[-15px]'>Tomas Davalillo</h5>
          <h6 className='mt-5 text-lg tracking-widest text-gray-500'>Autodidact Software</h6>
          <h6 className='text-lg tracking-widest text-gray-500'>Programmer</h6>
        </div>
        <div className='h-[50px] w-[300px] border-2 bg-blue-100 rounded-b-xl flex justify-center items-center'>

          <div className="icons">
            <a href="https://wa.me/351969948606" target={'_blank'}><FaIcons.FaWhatsapp size={30} /></a>
          </div>
          <div className="icons">
            <a href="https://www.instagram.com/jorgetomasvzla/" target={'_blank'}><FaIcons.FaInstagram size={30} /></a>
          </div>
          <div className="icons">
            <a href="https://www.linkedin.com/in/jorge-tomas-davalillo/" target={'_blank'}>< FaIcons.FaLinkedinIn size={30} /></a>
          </div>
          <div className="icons">
            <a href="https://github.com/spartacus20" target={'_blank'}><AiIcons.AiOutlineGithub size={30} /></a>
          </div>
        </div>
      </div>
      <article className=' xl:ml-10 sm:mb-10 sm:mt-10'>
        <h3 className='text-2xl font-bold text-blue-600'>ABOUT ME</h3>
        <p className='text-justify text-lg xl:ml-4 mt-6'>
          I am passionate about programming and am always looking for new learning. I love challenges and I am
          constantly improving my technical skills. Keeping up to date with the latest trends in the
          programming area is a priority for me. I am a dedicated and motivated person, always striving to
          to face new projects and find creative solutions. I am in constant personal and professional growth.
        </p>
      </article>
    </section>
  )
}

export default About