import React, { useState, useEffect } from 'react'
import "./projects.css"
import { BsGithub } from "react-icons/bs"



//import json from "./work.json"
function Projects() {


  const [repos, setRepos] = useState([])
  const [languages, setLanguages] = useState([{}]);

  useEffect(() => {
    fetch('https://api.github.com/users/spartacus20/repos')
      .then((response) => response.json())
      .then(data => setRepos(data))
  }, [])
 
  return (
    <div id="projects" className="xl:px-28 ">
      <div className="xl:ml-12">
        <div className="TitleProjects"> <BsGithub size={60} color="orange" className='border-2 rounded-full border-black' /><h2 className='font-bold '>Github Projects</h2></div>
        <div className="p-projects">
          {repos.map(repo => {

            var url = "https://github.com/spartacus20/" + repo.name;

            return (
              <div className='flex flex-col py-5'>
                <div className=""><h3 className='my-2 text-3xl font-semibold text-gray-600'><a href={url} target="_blank">{repo.name}</a></h3></div>
                <div className='flex mb-5'>Language: <h4 className='mx-2 text-blue-600 font-bold'>{repo.language}</h4></div>
                <hr className='border-black xl:w-[97%]' />
              </div>
            )
          })}


        </div>

      </div>
    </div>
  )
}

export default Projects