import React from "react";
import './colors.css';


function Colors(){

    return (
        <div className="principal">
            <div className="l-green"></div>
            <div className="l-red"></div>
            <div className="l-yellow"></div>
            <div className="l-blue"></div>
        </div>
    );
}

export default Colors; 