import React from 'react'
import mail from '../../Assets/mail.svg'
import location from '../../Assets/location.svg'
function Contact() {
    return (
        <section id="about" className="bg-white min-h-[300px] flex flex-col items-center xl:px-44  sm:px-6 xl:mt-[100px]">
            <h3 className='text-2xl font-bold text-blue-600 w-full xl:mt-[50px] sm:mt-5'>CONTACT</h3>
            <h4 className='text-2xl font-bold text-black 2 w-full '>Don't be shy! Hit me up! 👇</h4>
            <div className='flex  xl:flex-row sm:flex-col mt-6  w-full'>
                <div className='flex xl:flex-row sm:flex-col sm:justify-center sm:items-center sm:mt-4'>
                    <div className='w-[100px] h-[100px] rounded-full flex items-center justify-center bg-[#F7F6FE] shadow-xl'>
                        <img src={location} alt="" className='w-[50px]' />
                    </div>
                    <div className='mx-6 flex flex-col justify-center sm:mt-2'>
                        <h4 className='font-bold text-xl sm:text-center '>Location</h4>
                        <p className='text-[#8C7676] hover:text-orange-500'>Santarem, Portugal</p>
                    </div>
                </div>
                <div className='flex xl:flex-row sm:flex-col sm:justify-center sm:items-center sm:mt-4'>
                    <div className='w-[100px] h-[100px] rounded-full flex items-center justify-center bg-[#F7F6FE] shadow-xl'>
                        <img src={mail} alt="" className='w-[50px]' />
                    </div>
                    <div className='mx-6 flex flex-col justify-center sm:mt-2'>
                        <h4 className='font-bold text-xl sm:text-center'>Mail</h4>
                        <a href="mailto:jorgetomasdavalillo@gmail.com<"><p className='text-[#8C7676] hover:text-orange-500'>jorgetomasdavalillo@gmail.com</p></a>
                    </div>
                </div>



            </div>
        </section>
    )
}

export default Contact