import React from 'react'
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
function Footer() {
    return (
        <div className='w-full h-[180px] bg-[#2D2E32] xl:px-44 sm:px-6 xl:py-14 flex xl:justify-between xl:flex-row sm:flex-col sm:items-center'>
            <span className='text-white font-bold xl:text-xl sm:mt-7 sm:mb-7'>Copyright © 2023. All rights are reserved</span>
            <div className='flex'>
                <div className="icons">
                    <a href="https://www.linkedin.com/in/jorge-tomas-davalillo/" target={'_blank'}>< FaIcons.FaLinkedinIn size={30} className='text-white'/></a>
                </div>
                <div className="icons">
                    <a href="https://github.com/spartacus20" target={'_blank'}><AiIcons.AiOutlineGithub size={30}  className='text-white'/></a>
                </div>
            </div>
        </div>
    )
}

export default Footer