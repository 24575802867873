import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom';
import './home.css'
import Colors from '../../Components/Colors/Colors'
import Navbar from '../../Components/Navbar/Navbar'
import About from '../../Components/About/About'
import 'animate.css'
import Aos from 'aos';
import "aos/dist/aos.css"
import Projects from '../../Components/Projects/Projects';
import webdevelopment from '../../Assets/web-development.svg'
import html from '../../Assets/html.svg'
import css from '../../Assets/css.svg'
import js from '../../Assets/js.svg'
import react from '../../Assets/react.svg'
import laravel from '../../Assets/laravel.png'
import Contact from '../../Components/Contact/Contact';

function Home() {

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, [])

  return (
    <>
    <div className='2xl:px-44'>
      <div className=' xl:px-48 sm:px-10 mt-[100px] mb-[100px] flex sm:flex-col-reverse xl:flex-row '>
        <div className='xl:mr-[100px]'>
          <h1 className='xl:text-[6rem] sm:text-[3rem] font-bold'> Hello! </h1>
          <h2 className='xl:text-[5rem] sm:text-[2.5rem] font-bold xl:mt-[-40px] xl:ml-[80px]'>I´m Jorge</h2>
          <h3 className='xl:text-[2rem] sm:text-[1.5rem] font-semibold text-gray-600 xl:ml-[150px]'>Junior Sofware Developer</h3>
          <div className='flex xl:flex-row sm:flex-col items-center xl:mt-36 '>
            <h4 className='xl:text-[1.5rem] sm:text-[1.2rem] font-bold xl:border-r-2 xl:border-b-0 sm:border-b-2 sm:mb-4 sm:mt-4 sm:py-4  border-gray-300 xl:px-5 xl:mr-3 xl:w-[190px]'>Tech Stack: </h4>
            <div className='flex sm:px-5 sm:flex-wrap sm:justify-center xl:flex-nowrap'>
              <div className='rounded-full sha bg-white border-2 w-[50px] h-[50px] xl:mx-4 sm:mx-2 flex items-center justify-center '>
                <img src={html} alt="Jorge Tomas - HTML" className='h-[30px]' />
              </div>
              <div className='rounded-full sha bg-white border-2 w-[50px] h-[50px] xl:mx-4 sm:mx-2 flex items-center justify-center'>
                <img src={css} alt="Jorge Tomas - CSS" className='h-[34px]' />
              </div>
              <div className='rounded-full sha bg-white border-2 w-[50px] h-[50px] xl:mx-4 sm:mx-2 flex items-center justify-center'>
                <img src={js} alt="Jorge Tomas - JS" className='h-[30px]' />
              </div>
              <div className='rounded-full sha bg-white border-2 w-[50px] h-[50px]xl:mx-4 sm:mx-2 flex items-center justify-center'>
                <img src={react} alt="Jorge Tomas - REACT" className='h-[30px]' />
              </div>
              <div className='rounded-full sha bg-white border-2 w-[50px] h-[50px] xl:mx-4 flex items-center justify-center'>
                <img src={laravel} alt="Jorge Tomas - LARAVEL" className='h-[34px]' />
              </div>
            </div>
          </div>


        </div>
        <img src={webdevelopment} alt="Jorge Tomas - Web developement" className=' xl:ml-[-100px]' />
      </div>
      <div data-aos="fade-right" className="xl:px-40 sm:px-5 rounded-xl mb-[150px] mt-[150px]">
        <About />
      </div>
      <div data-aos="fade-up" className='sm:px-6'>
        <Projects />
      </div>
    </div>
    <div>
        <Contact/>
      </div>
    </>
  )
}

export default Home